// Hooks
import { useState } from 'react';

// Services
import { sendEmailContactUs } from '../../services/emailApi';

// Components
import Alert from './Alert';
import Button from './Button';
import Loading from './Loading';

// Libraries
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ContactForm = () => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isHandled, setIsHandled] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validateInputs = () => {
    if (Name && Email && Phone && Message) {
      return true;
    }
    return false;
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsError(false);
    setIsHandled(true);

    if (!validateInputs()) return;

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('ContactForm');
    const data = { Name, Email, Phone, Message };
    try {
      const resp = await sendEmailContactUs({ ...data, tokenCaptcha: token });
      if (!resp || resp.message !== 'Email sent!') {
        setIsError(true);
      } else {
        setEmail('');
        setName('');
        setPhone('');
        setMessage('');
      }
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <form className="md:w-128 inline-block w-full" onSubmit={handleForm}>
      <div className="mb-6">
        <input
          type="text"
          className="form-control block w-full px-4 py-2 text-xl font-normal text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          placeholder="Your Name*"
          required
          value={Name}
          onChange={({ target: { value } }) => setName(value)}
        />
      </div>

      <div className="mb-6">
        <input
          type="text"
          className="form-control block w-full px-4 py-2 text-xl font-normal text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          placeholder="Your Email*"
          required
          value={Email}
          onChange={({ target: { value } }) => setEmail(value)}
        />
      </div>

      <div className="mb-6">
        <input
          type="text"
          className="form-control block w-full px-4 py-2 text-xl font-normal text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          placeholder="Your Phone*"
          required
          value={Phone}
          onChange={({ target: { value } }) => setPhone(value)}
        />
      </div>

      <div className="mb-6">
        <textarea
          type="text"
          className="form-control block w-full px-4 py-2 text-xl font-normal text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          placeholder="Your Message*"
          required
          value={Message}
          onChange={({ target: { value } }) => setMessage(value)}
        ></textarea>
      </div>
      <div className="text-center">
        <Button
          className="bg-white-200 text-mid-blue-900 hover:bg-white-50 focus:bg-white-50 active:bg-white-50"
          type="submit"
          disabled={(isHandled && isLoading) || !validateInputs()}
        >
          Submit Now
        </Button>
        <div className="mt-5">
          {isLoading ? <Loading isLight /> : isHandled && <Alert open={true} severity={isError ? 'error' : 'success'} message={isError ? 'Error, try later!' : 'Email sent!'} />}
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
