import React, { useState } from 'react';
import CardService from '../components/services/CardService';
import Title from '../components/shared/Title';

import BgTalkUs from '../assets/imgs/bg-talk-us.png';
import Button from '../components/shared/Button';
import ContactForm from '../components/shared/ContactForm';
import { useQuery } from 'react-query';
import { getService } from '../services/serviceApi';
import Loading from '../components/shared/Loading';
import Alert from '../components/shared/Alert';

const Services = () => {
  const [openForm, setOpenForm] = useState(false);
  const { isLoading, isError, data } = useQuery(['services', { order: [{ by: 'Order', type: 'asc' }] }], getService, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <>
      <div className="w-full bg-center bg-cover bg-no-repeat md:px-52 py-20 px-10 mb-20 text-xl" style={{ backgroundImage: `url(${BgTalkUs})` }}>
        <div className="3xl:max-w-[1500px] 3xl:inline-block">
          <Title text1="Our" text2="Services" colorForText={2} />

          <p className="text-center mb-5">
            We offer software development services in both <b>project team</b> and <b>staff augmentation</b> models. Our developers are focused on creating high-level digital
            products and supporting critical mission systems.
          </p>
          <p className="text-center">Among the services we offer are:</p>
        </div>
      </div>

      <div className="3xl:max-w-[1500px] 3xl:inline-block">
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert open={isError} severity="warning" message="Error loading data, try again later" />
        ) : data && data.data.length > 0 ? (
          <div className="xl:px-52 md:px-20 px-10 pt-1">
            {data.data.map((service, i) => (
              <CardService isRight={i % 2 === 0 ? true : false} icon={service.LogoUrl} image={service.ImageUrl} title={service.Title} id={service.Id} key={service.Id}>
                <p className="text-light-grey-500">{service.Description}</p>
                {service.ListSection && (
                  <ul className="text-light-grey-500 grid md:grid-cols-2 mt-5">
                    {service.ListSection.split(',').map((element) => (
                      <li key={element}>{element.trim()}</li>
                    ))}
                  </ul>
                )}
              </CardService>
            ))}
          </div>
        ) : (
          <Alert open={true} message="No services available at this time" severity="info" />
        )}
      </div>

      <div className="w-full bg-custom mt-20">
        <div className="container mx-auto py-8">
          <div className="w-full text-center">
            <p className="w-8/12 inline-flex items-center justify-center text-white-50 text-center text-3xl mb-5">Get to know about our services</p>
          </div>

          <div className="text-center mt-5">
            <Button
              className="border border-white-50 bg-white-50 text-mid-blue-900 hover:bg-white-200 focus:bg-white-200 active:bg-white-200"
              onClick={() => setOpenForm(!openForm)}
            >
              {openForm ? 'Hide Form' : "Let's talk!"}
            </Button>
          </div>

          {openForm && (
            <div className="text-center mt-10 p-10">
              <ContactForm />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Services;
