import React from 'react';
import CareersTitle from '../components/careers/CareersTitle';
import CareersJoin from '../components/careers/CareersJoin';
import OpenPositions from '../components/careers/OpenPositions';
import Benefits from '../components/careers/Benefits';
import Testimonials from '../components/careers/Testimonials';
import Kampus from '../components/careers/Kampus';

const Careers = () => {
  return (
    <>
      <CareersTitle />
      <CareersJoin />
      <OpenPositions />
      <Benefits />
      <Testimonials />
      <Kampus />
    </>
  );
};

export default Careers;
