import React from 'react';

const ItemCard = ({ img, name }) => {
  return (
    <div className="rounded md:p-8 p-3 shadow transition-all delay-150">
      <div className="text-center">
        <img className="inline-block hover:scale-105" src={img} alt={name} />
        <h5 className="mt-5 text-lg text-dark-grey-800 font-semibold">{name}</h5>
      </div>
    </div>
  );
};

export default ItemCard;
