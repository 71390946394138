import React from 'react';
import { dataSeverity } from '../../utils/general';

const Alert = ({ open, message, severity }) => {
  if (!open) {
    return null;
  }

  return (
    <div className={`flex items-center ${dataSeverity(severity).color} text-sm font-bold px-4 py-3 shadow-lg rounded-lg transition delay-200 ease-linear`} role="alert">
      <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" dangerouslySetInnerHTML={{ __html: dataSeverity(severity).icon }} />

      <p>{message}</p>
    </div>
  );
};

export default Alert;
