import _ from 'underscore';
import axiosInstance from './axiosInstance';

export const getTestimonials = async ({ queryKey }) => {
  const { order, filters } = queryKey[1];
  const headers = { 'x-is-paginated': 'false' };

  if (filters && !_.isEmpty(filters)) {
    headers['x-filters'] = JSON.stringify(filters);
  }

  if (order && !_.isEmpty(order)) {
    let stringOrder = '';
    order.forEach((element) => {
      stringOrder += stringOrder === '' ? `${element.by} ${element.type}` : `, ${element.by} ${element.type}`;
    });
    headers['x-order'] = stringOrder;
  }
  const { data } = await axiosInstance().get('/testimonial', {
    headers,
  });
  return data;
};
