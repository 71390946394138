import React, { useEffect, useState } from 'react';
import Location from '../../../assets/imgs/location.png';
import useMountTransition from '../../../hooks/useMountTransition';
import Button from '../../shared/Button';
import ApplyForm from './ApplyForm';

const PositionModal = ({ open, position, handleCloseModal }) => {
  const [isApplying, setIsApplying] = useState(false);
  const hasTransitionedIn = useMountTransition(open, 550);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'unset';
  }, [open]);

  if (!open && !hasTransitionedIn) {
    return null;
  }

  const infoActions = () => (
    <>
      <Button
        className="border border-orange-700 text-orange-700 hover:bg-orange-700 focus:bg-orange-700 active:bg-orange-700 hover:text-white-50 focus:text-white-50 active:text-white-50 pt-2 pb-2"
        onClick={handleCloseModal}
      >
        Close
      </Button>

      <Button
        className="bg-blue-800 text-white-50 border border-blue-800 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900 hover:border-mid-blue-900 focus:border-mid-blue-900 active:border-mid-blue-900 pt-2 pb-2"
        onClick={() => setIsApplying(true)}
      >
        Apply for this position
      </Button>
    </>
  );

  const applyActions = () => (
    <>
      <Button
        className="border border-orange-700 text-orange-700 hover:bg-orange-700 focus:bg-orange-700 active:bg-orange-700 hover:text-white-50 focus:text-white-50 active:text-white-50 pt-2 pb-2"
        onClick={() => setIsApplying(false)}
      >
        Back
      </Button>
    </>
  );

  return (
    <div className="relative z-40 inset-0" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={handleCloseModal}>
      <div className="fixed inset-0 bg-dark-blue-900 bg-opacity-75 transition-opacity"></div>

      <div className={`fixed inset-0 z-50 overflow-y-auto transition delay-200 ease-linear ${hasTransitionedIn && open ? '' : '-translate-y-full'}`}>
        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white-50 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-5xl"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="bg-white-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <header className="md:flex justify-between items-center">
                <div>
                  <h3 className="text-xl font-bold leading-6 text-blue-700" id="modal-title">
                    {position?.Title}
                  </h3>
                  <p className="text-dark-grey-500 text-sm">{position?.Department}</p>
                </div>
                <div className="flex items-center gap-1">
                  <img src={Location} alt="location marker" />
                  <p>{position?.Location}</p>
                </div>
              </header>
              {/* content */}
              <div className="mt-2 h-[550px] overflow-y-auto text-lg">
                {isApplying ? <ApplyForm position={position} /> : <div dangerouslySetInnerHTML={{ __html: position.Description }} />}
              </div>
              {/* /content */}
            </div>
            <div className="bg-dark-grey-50 px-4 py-3 flex md:justify-between justify-center flex-wrap-reverse sm:px-6 gap-4">{isApplying ? applyActions() : infoActions()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PositionModal;
