import React from 'react';
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const words = [
  { text: 'Amusing', value: 7 },
  { text: 'Brave', value: 7 },
  { text: 'Calm', value: 7 },
  { text: 'Careful', value: 7 },
  { text: 'Challenger', value: 10 },
  { text: 'Charming', value: 7 },
  { text: 'Cheerful', value: 7 },
  { text: 'Clever', value: 7 },
  { text: 'Coherent', value: 7 },
  { text: 'Confident', value: 7 },
  { text: 'Cool', value: 7 },
  { text: 'Creative', value: 10 },
  { text: 'Dependable', value: 7 },
  { text: 'Easy-going', value: 7 },
  { text: 'Efficient', value: 11 },
  { text: 'Enthusiastic', value: 7 },
  { text: 'Exciting', value: 7 },
  { text: 'Fair', value: 7 },
  { text: 'Familiar', value: 15 },
  { text: 'Friendly', value: 7 },
  { text: 'Generous', value: 7 },
  { text: 'Gentle', value: 7 },
  { text: 'Genuine', value: 7 },
  { text: 'Great', value: 9 },
  { text: 'Happy', value: 7 },
  { text: 'Hard-working', value: 7 },
  { text: 'Healthy', value: 7 },
  { text: 'Helpful', value: 7 },
  { text: 'Honest', value: 9 },
  { text: 'Interesting', value: 7 },
  { text: 'Innovation', value: 11 },
  { text: 'Kind', value: 7 },
  { text: 'Lovely', value: 7 },
  { text: 'Nice', value: 9 },
  { text: 'Obliging', value: 7 },
  { text: 'Optimistic', value: 7 },
  { text: 'Organized', value: 9 },
  { text: 'Patient', value: 7 },
  { text: 'Peaceful', value: 7 },
  { text: 'Pleasant', value: 7 },
  { text: 'Polite', value: 7 },
  { text: 'Powerful', value: 7 },
  { text: 'Proud', value: 7 },
  { text: 'Responsible', value: 9 },
  { text: 'Right', value: 7 },
  { text: 'Strong', value: 7 },
  { text: 'Successful', value: 12 },
  { text: 'Sympathetic', value: 7 },
  { text: 'Talented', value: 9 },
  { text: 'Thoughtful', value: 7 },
];

const options = {
  colors: ['#0487fa', '#ff7849', '#ffc82c', '#d62728', '#e316b6', '#112fd2', '#13ce66', '#3845ca', '#0487fa'],
  enableTooltip: false,
  deterministic: false,
  fontFamily: 'Graphie',
  fontSizes: [30, 130],
  fontStyle: 'normal',
  fontWeight: 'normal',
  padding: 4,
  rotations: 1,
  rotationAngles: [0, 90],
  scale: 'sqrt',
  spiral: 'archimedean',
  transitionDuration: 1000,
};

const WordCloud = () => {
  return (
    <div className="flex justify-center">
      <ReactWordcloud words={words} options={options} className="flex justify-center max-w-screen-md" minSize={[2000, 350]} />
    </div>
  );
};

export default WordCloud;
