import React, { useState } from 'react';
// import { useQuery } from 'react-query';
import Button from '../shared/Button';
// import Loading from '../shared/Loading';
// import { getDepartments } from '../../services/careersApi';
import { generateFilter } from '../../utils/general';

const FormSearchPosition = ({ getDataWithFilters, resetFilters }) => {
  // const { isLoading, data } = useQuery(['departments'], getDepartments, {
  //   retry: false,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  // });

  const [keyword, setKeyword] = useState('');
  const [departmentSelected, setDepartmentSelected] = useState('');

  const onChangeKeyword = ({ target: { value } }) => {
    setKeyword(value);
  };

  // const onChangeDepartment = ({ target: { value } }) => {
  //   setDepartmentSelected(value);
  // };

  const handleClear = () => {
    setKeyword('');
    setDepartmentSelected('');
    resetFilters();
  };

  const searchPositions = (e) => {
    e.preventDefault();
    let filters = {};
    if (keyword) {
      filters['default'] = generateFilter(filters['default'], 'Title', keyword, 'lk');
    }

    if (departmentSelected) {
      filters['default'] = generateFilter(filters['default'], 'Department', departmentSelected, 'eq');
    }
    getDataWithFilters(filters);
  };

  return (
    <form onSubmit={searchPositions} className="md:flex items-center justify-center gap-4 mb-10 grid">
      <input
        className="form-control block px-4 py-2 h-10 text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
        placeholder="Search by keyword"
        value={keyword}
        onChange={onChangeKeyword}
      />
      {/* 
      {isLoading ? (
        <Loading />
      ) : (
        <select
          name="department"
          className="form-control block px-4 py-2 h-10 text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          value={departmentSelected}
          placeholder="Select  department"
          onChange={onChangeDepartment}
        >
          <option value="" disabled>
            Select department
          </option>
          {data &&
            data.data.map((department) => (
              <option value={department} key={department}>
                {department}
              </option>
            ))}
        </select>
      )} */}

      <Button className="bg-blue-800 text-white-50 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900" type="submit">
        Search
      </Button>

      {(keyword !== '' || departmentSelected !== '') && (
        <Button
          className="bg-white-50 text-orange-700 hover:bg-orange-700 focus:bg-orange-700 active:bg-orange-700 hover:text-white-50 focus:text-white-50 active:text-white-50"
          onClick={handleClear}
        >
          Clear
        </Button>
      )}
    </form>
  );
};

export default FormSearchPosition;
