import React, { useEffect, useRef } from 'react';
import useLayoutContext from '../../hooks/useLayoutContext';
import useMountTransition from '../../hooks/useMountTransition';
import { dataSeverity } from '../../utils/general';

const Snackbar = () => {
  const {
    snackbarInfo: { open, message, severity },
    setInfoSnackbar,
  } = useLayoutContext();

  const timeout = useRef();
  const hasTransitionedIn = useMountTransition(open, 1000);

  useEffect(() => {
    if (hasTransitionedIn && open) {
      timeout.current = setTimeout(() => {
        setInfoSnackbar({ open: false });
      }, 3000);
    }

    return () => {
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line
  }, [hasTransitionedIn]);

  if (!open && !hasTransitionedIn) {
    return null;
  }

  return (
    <div
      className={`flex items-center ${dataSeverity(severity).color} text-sm font-bold px-4 py-3 fixed bottom-7 left-7 shadow-lg rounded-lg transition delay-200 ease-linear z-50 ${
        hasTransitionedIn && open ? '' : '-translate-x-96'
      }`}
      role="alert"
    >
      <svg className="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" dangerouslySetInnerHTML={{ __html: dataSeverity(severity).icon }} />
      <p>{message}</p>
    </div>
  );
};

export default Snackbar;
