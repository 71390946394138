import { useState } from 'react';
import Alert from '../shared/Alert';
import Loading from '../shared/Loading';
import Title from '../shared/Title';
import FormSearchPosition from './FormSearchPosition';
import PositionCard from './positions/PositionCard';
import PositionModal from './positions/PositionModal';
import { getCareers } from '../../services/careersApi';
import BgTalkUs from '../../assets/imgs/bg-talk-us.png';
import useData from '../../hooks/useData';
import ApplyAnyPositionContainer from './ApplyAnyPositionContainer';

const OpenPositions = () => {
  const [modalInfo, setModalInfo] = useState({ open: false, position: null });
  const { rows, page, count, isError, isLoading, sliceRows, getDataNextPage, getDataWithFilters, resetFilters } = useData({
    name: 'careers',
    getAllAnyWithFilters: getCareers,
    orderBy: [{ type: 'desc', by: 'CreatedAt' }],
  });

  const handleOpenInfo = (position) => {
    setModalInfo({ open: true, position });
  };

  const handleCloseModal = () => {
    setModalInfo((prev) => ({ ...prev, open: false }));
  };

  return (
    <div className="bg-white-100 py-14 bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${BgTalkUs})` }}>
      <div className="3xl:max-w-[1500px] 3xl:inline-block">
        <Title text1="Open" text2="Positions" colorForText={2} />
        <div className="xl:px-52 md:px-20 px-10">
          <FormSearchPosition getDataWithFilters={getDataWithFilters} resetFilters={resetFilters} />
          {isLoading ? (
            <Loading />
          ) : isError ? (
            <Alert open={isError} severity="warning" message="Error loading data, try again later" />
          ) : rows.length > 0 ? (
            <>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
                {rows.map((position) => (
                  <PositionCard key={position.Id} onClick={handleOpenInfo} position={position} />
                ))}
              </div>
              <PositionModal {...modalInfo} handleCloseModal={handleCloseModal} />
            </>
          ) : (
            <Alert open={true} message="No open position at this time" severity="info" />
          )}
          <div className="flex items-center justify-center mt-10 text-lg">
            <button
              className="text-mid-blue-900 border bg-white-50 border-mid-blue-900 py-1 px-6 rounded shadow hover:bg-mid-blue-900 hover:text-white-50 transition-all ease-linear disabled:cursor-default disabled:border-mid-blue-900 disabled:bg-white-100 disabled:hover:text-mid-blue-900 disabled:shadow-none"
              disabled={sliceRows.end < count ? false : true}
              onClick={() => getDataNextPage(page + 1)}
            >
              More Positions
            </button>
          </div>
          <ApplyAnyPositionContainer />
        </div>
      </div>
    </div>
  );
};

export default OpenPositions;
