import Title from '../components/shared/Title';
import WordCloud from '../components/team/WordCloud';
import BgTalkUs from '../assets/imgs/bg-talk-us.png';
import AdvisoryContainer from '../components/team/AdvisoryContainer';
import SherpasContainer from '../components/team/SherpasContainer';
import ManagementContainer from '../components/team/ManagementContainer';

const Team = () => {
  return (
    <>
      <div className="bg-custom md:px-52 py-32 pt-20 px-10">
        <div className="3xl:max-w-[1500px] 3xl:inline-block">
          <ManagementContainer />
        </div>
      </div>
      <div className="bg-white-100 py-14 bg-center bg-cover bg-no-repeat xl:px-52 md:px-20 px-10" style={{ backgroundImage: `url(${BgTalkUs})` }}>
        <div className="3xl:max-w-[1500px] 3xl:inline-block">
          <SherpasContainer />
        </div>
      </div>
      <div className="my-20">
        <Title text1="Why be" text2="a Sherpa?" colorForText={2} />
        <WordCloud />
      </div>
      <div className="bg-white-100 py-14 bg-center bg-cover bg-no-repeat xl:px-52 md:px-20 px-10" style={{ backgroundImage: `url(${BgTalkUs})` }}>
        <div className="3xl:max-w-[1500px] 3xl:inline-block">
          <AdvisoryContainer />
        </div>
      </div>
    </>
  );
};

export default Team;
