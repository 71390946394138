import React from 'react';

const Paginator = ({ count, lastPage, page, handleChangePage }) => {
  const handlePreviousPage = () => {
    handleChangePage(page - 1);
  };

  const handleNextPage = () => {
    handleChangePage(page + 1);
  };

  if (count === 0) {
    return null;
  }

  const classesActive = 'text-dark-grey-600 hover:text-blue-700 cursor-pointer';
  const classesDisabled = 'text-dark-grey-300 cursor-default';

  return (
    <div className="flex items-center justify-center mt-10 text-lg">
      <div className={`flex items-center pt-3 mr-10 ${page !== 0 ? classesActive : classesDisabled}`} onClick={page !== 0 ? handlePreviousPage : undefined}>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.1665 4L4.49984 7.33333" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.1665 4.00002L4.49984 0.666687" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <p className="ml-3 font-medium leading-none ">Previous</p>
      </div>

      <div className={`flex items-center pt-3 ${lastPage < count ? classesActive : classesDisabled}`} onClick={lastPage < count ? handleNextPage : undefined}>
        <p className="font-medium leading-none mr-3">Next</p>
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.1665 4H12.8332" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.5 7.33333L12.8333 4" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M9.5 0.666687L12.8333 4.00002" stroke="currentColor" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </div>
  );
};

export default Paginator;
