import { useEffect, useRef } from 'react';

const CardService = ({ icon, image, title, isRight = false, children, id }) => {
  const divRef = useRef(null);
  const idParam = window.location.hash.slice(1);

  useEffect(() => {
    if (divRef.current.id === idParam) {
      window.scrollTo({ behavior: 'smooth', left: 0, top: divRef.current.offsetTop - 100 });
    }
  }, [idParam]);

  if (isRight) {
    return (
      <div className="grid md:grid-cols-2 items-center gap-10 mb-10" id={id} ref={divRef}>
        <div className="md:text-right text-center text-xl">
          <img src={icon} alt="icon" className="inline-block w-20 mb-5" />
          <h3 className="font-black text-3xl mb-2">{title}</h3>
          {children}
        </div>
        <div className="text-center md:block hidden">
          <img src={image} alt="image" className="w-128 inline-block rounded-xl shadow-lg" />
        </div>
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-2 items-center gap-10 mb-10" id={id} ref={divRef}>
      <div className="text-center md:block hidden">
        <img src={image} alt="image" className="w-128 inline-block rounded-xl shadow-lg" />
      </div>

      <div className="md:text-left text-center text-xl">
        <img src={icon} alt="icon" className="inline-block w-20 mb-5" />
        <h3 className="font-black text-3xl mb-2">{title}</h3>
        {children}
      </div>
    </div>
  );
};

export default CardService;
