import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import useLayoutContext from '../../hooks/useLayoutContext';

const Title = ({ text1, text2, colorForText = 1, align = 'horizontal', isLight = false }) => {
  const part1 = text1 ? text1.replace(' ', '-').toLowerCase() : '';
  const part2 = text2 ? text2.replace(' ', '-').toLowerCase() : '';
  const url = `${part1}${part1 && part2 && '-'}${part2}`;

  const titleRef = useRef(null);
  const { pathname } = useLocation();
  const { setInfoSnackbar } = useLayoutContext();

  const id = window.location.hash.slice(1);

  useEffect(() => {
    if (titleRef.current.id === id) {
      window.scrollTo({ behavior: 'smooth', left: 0, top: titleRef.current.offsetTop - 100 });
    }
  }, [id]);

  const onCopy = () => {
    navigator.clipboard.writeText(`${location.origin}${pathname}#${url}`);
    setInfoSnackbar({ open: true, message: `Link copied: ${text1} ${text2}` });
  };

  return (
    <div className="text-center">
      <h4
        className={`text-center text-4xl mb-10 font-thin fk-title relative inline-block hover:cursor-pointer ${align} ${isLight ? 'text-white-50' : ''}`}
        onClick={onCopy}
        ref={titleRef}
        id={url}
      >
        {text1 && (colorForText === 1 ? <span className={`font-bold ${isLight ? '' : 'text-blue-700'}`}>{text1}</span> : text1)}{' '}
        {text2 && (colorForText === 2 ? <span className={`font-bold ${isLight ? '' : 'text-blue-700'}`}>{text2}</span> : text2)}
      </h4>
    </div>
  );
};

export default Title;
