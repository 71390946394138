import React, { useEffect } from 'react';
import useData from '../../hooks/useData';
import { getPosts } from '../../services/blogApi';
import Alert from '../shared/Alert';
import Loading from '../shared/Loading';
import Paginator from '../shared/Paginator';
import BlogCard from './BlogCard';

const PostsContainer = ({ categorySelected }) => {
  const { rows, page, count, isError, isLoading, sliceRows, getDataNextPage, getDataWithFilters, resetFilters } = useData({
    name: 'posts',
    getAllAnyWithFilters: getPosts,
    orderBy: [
      { type: 'desc', by: 'IsFeatured' },
      { type: 'desc', by: 'CreatedAt' },
    ],
    isEnabled: false,
  });

  useEffect(() => {
    filterData();
    // eslint-disable-next-line
  }, [categorySelected]);

  const filterData = () => {
    if (categorySelected === 'All') {
      resetFilters();
    } else {
      getDataWithFilters({ default: `$filter=Category eq '${categorySelected}'` });
    }
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Alert open={isError} severity="warning" message="Error loading data, try again later" />
      ) : rows.length > 0 ? (
        <>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-4">
            {rows.slice(sliceRows.start, sliceRows.end).map((post) => (
              <BlogCard key={post.Id} {...post} />
            ))}
          </div>
        </>
      ) : (
        <Alert open={true} message="There are not posts at this time" severity="info" />
      )}
      <Paginator count={count} lastPage={sliceRows.end} page={page} handleChangePage={getDataNextPage} />
    </div>
  );
};

export default PostsContainer;
