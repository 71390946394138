import { useEffect, useMemo, useState } from 'react';

const useData = ({ getAllAnyWithFilters, name, initRowsPerPage = 20, initialFilters = '', orderBy, isEnabled = true }) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState(initialFilters);
  const [lastPageCalled, setLastPageCalled] = useState(0);
  const [continuationToken, setContinuationToken] = useState('');
  const [order, setOrder] = useState(orderBy || []);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const sliceRows = useMemo(() => ({ start: page * rowsPerPage, end: page * rowsPerPage + rowsPerPage }), [page, rowsPerPage]);

  useEffect(() => {
    if (isEnabled) {
      gettingData();
    }
    // eslint-disable-next-line
  }, []);

  const gettingData = async () => {
    setIsLoading(true);
    try {
      const result = await getAllAnyWithFilters({
        queryKey: [name, { filters, rowsPerPage, order }],
      });
      if (result && result.data) {
        const { continuationToken, count, data, rowsPerPage } = result;
        setContinuationToken(continuationToken);
        setCount(count);
        setRows(data);
        setRowsPerPage(rowsPerPage);
        setLastPageCalled(0);
        setPage(0);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsError(true);
      setIsLoading(false);
    }
  };

  const resetFilters = () => {
    getDataWithFilters('');
  };

  const getDataNextPage = async (newPage) => {
    setIsLoading(true);
    try {
      setPage(newPage);
      if (newPage > lastPageCalled) {
        setLastPageCalled(newPage);
        const result = await getAllAnyWithFilters({
          queryKey: [name, { filters, continuationToken, rowsPerPage, order }],
        });
        if (result && result.data) {
          const { continuationToken, count, data } = result;
          setContinuationToken(continuationToken);
          setCount(count);
          setRows((rows) => [...rows, ...data]);
        } else {
          setIsError(true);
        }
      }
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getDataWithFilters = async (filters) => {
    setIsLoading(true);
    try {
      setFilters(filters);
      setLastPageCalled(0);
      setPage(0);
      const result = await getAllAnyWithFilters({
        queryKey: [name, { filters, rowsPerPage, order }],
      });
      if (result && result.data) {
        const { continuationToken, count, data } = result;
        setContinuationToken(continuationToken);
        setCount(count);
        setRows(data);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const getDataSorted = async (newOrder) => {
    setIsLoading(true);
    try {
      setPage(0);
      setLastPageCalled(0);
      setOrder(newOrder);
      const result = await getAllAnyWithFilters({
        queryKey: [name, { filters, rowsPerPage, order: newOrder }],
      });
      if (result && result.data) {
        const { continuationToken, count, data } = result;
        setContinuationToken(continuationToken);
        setCount(count);
        setRows(data);
      } else {
        setIsError(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  return {
    rows,
    page,
    count,
    isError,
    isLoading,
    sliceRows,
    getDataNextPage,
    getDataWithFilters,
    getDataSorted,
    resetFilters,
  };
};

export default useData;
