import { useState } from 'react';
import Title from '../shared/Title';
import BgTalkUs from '../../assets/imgs/bg-talk-us.png';
import Button from '../shared/Button';
import WeWork from '../../assets/imgs/kampus/we-work.png';
import Methodology from '../../assets/imgs/kampus/Methodology.png';
import Objectives from '../../assets/imgs/kampus/Objectives.png';
import ApplyAnyPositionModal from '../../components/careers/ApplyAnyPositionModal';

const Kampus = () => {
  const [modalInfo, setModalInfo] = useState(false);

  const handleOpenInfo = () => {
    setModalInfo(true);
  };

  const handleCloseModal = () => {
    setModalInfo(false);
  };

  return (
    <>
      <div className="xl:px-52 md:px-20 px-10 py-20 bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${BgTalkUs})` }}>
        <Title text1="Kampus" />
        <p className="text-xl text-center mb-4">We created Kampus to increase the technical and soft skills of our Sherpas.</p>
      </div>
      <div className="xl:px-52 md:px-20 px-10 py-12 text-center 3xl:max-w-[1500px] 3xl:inline-block">
        <div className="grid md:grid-cols-3">
          <div className="md:border-r-2 border-r-blue-700 text-center md:p-8">
            <div className="bg-blue-700 inline-block p-5 rounded-full shadow">
              <img src={Objectives} alt="icon" className="w-12" />
            </div>
            <h6 className="font-bold text-xl mb-5">Objective</h6>
            <p className="text-xl">
              We want to align the clients' capabilities with the needs in the market and the clients' digital route; through this, we remain in constant preparation to contribute
              to the clients' strategic projects.
            </p>
          </div>

          <div className="md:border-r-2 border-r-blue-700 text-center md:p-8 md:mt-0 mt-14">
            <div className="bg-blue-700 inline-block p-5 rounded-full shadow">
              <img src={WeWork} alt="icon" className="w-12" />
            </div>
            <h6 className="font-bold text-xl mb-5">How we work</h6>
            <p className="text-xl">
              Kampus includes three lines, internship, learning, and content. Internship prepares engineering students. Learning focuses on building community knowledge. Content
              finds the right content for the entire community.
            </p>
          </div>

          <div className="text-center md:p-8 md:mt-0 mt-14">
            <div className="bg-blue-700 inline-block p-5 rounded-full shadow">
              <img src={Methodology} alt="icon" className="w-12" />
            </div>
            <h6 className="font-bold text-xl mb-5">Methodology</h6>
            <p className="text-xl">
              We use the Scrum framework to create software solutions. Our seniors prepare courses on new technologies, frameworks, and tools and share them with the rest of the
              company; we work together in finding the best content for our Sherpas.
            </p>
          </div>
        </div>
        <p className="mt-16 mb-3 text-xl">If you would like to intern with us, please apply here.</p>
        <Button className=" bg-blue-800 text-white-50 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900" onClick={handleOpenInfo}>
          Be an intern Sherpa
        </Button>
        <ApplyAnyPositionModal handleCloseModal={handleCloseModal} open={modalInfo} Title="Intern Sherpa" />
      </div>
    </>
  );
};

export default Kampus;
