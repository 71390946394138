// Hooks
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Components
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Snackbar from './shared/Snackbar';

// Libraries
import ReactGA from 'react-ga4';
import ScrollToTop from './shared/ScrollToTop';

function Layout() {
  let location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  return (
    <div>
      <ScrollToTop />
      <Header />
      <main className="relative mt-20 text-center">
        <Snackbar />
        <Outlet />
      </main>
      <Footer />
    </div>
  );
}
export default Layout;
