import AnimatedNumbers from 'react-animated-numbers';
import { validateNumber } from '../../utils/general';

const configs = [
  { mass: 1, tension: 220, friction: 100 },
  { mass: 1, tension: 180, friction: 130 },
  { mass: 1, tension: 280, friction: 90 },
  { mass: 1, tension: 180, friction: 135 },
  { mass: 1, tension: 260, friction: 100 },
  { mass: 1, tension: 210, friction: 180 },
];

const MetricItem = ({ Title, Description, Value }) => {
  const characters = Value ? Value.split(',') : [];
  return (
    <div className="relative flex justify-center">
      <div className={`text-center ${Description ? 'group hover:cursor-pointer' : 'cursor-default'}`}>
        <h4 className="font-black text-4xl text-blue-800 flex justify-center">
          {characters.map((character, i) =>
            validateNumber(character) ? (
              <AnimatedNumbers includeComma={false} animateToNumber={character} configs={configs} key={character} />
            ) : (
              <span key={character} className={i === characters.length - 1 ? 'ml-2' : ''}>
                {character}
              </span>
            )
          )}
        </h4>
        <p className="text-lg text-light-grey-500 inline-block">{Title}</p>
        {Description && (
          <span className="absolute top-16 left-3 right-3 mx-auto my-0 scale-0 transition-all rounded bg-dark-grey-800 p-2 text-xs text-white-50 group-hover:scale-100">
            {Description}
          </span>
        )}
      </div>
    </div>
  );
};

export default MetricItem;
