import _ from 'underscore';
import axiosInstance from './axiosInstance';

export const getCareers = async ({ queryKey }) => {
  const { filters, continuationToken, order } = queryKey[1];
  const headers = { 'x-page-size': 9, 'x-is-paginated': true };
  if (filters && !_.isEmpty(filters)) {
    headers['x-filters'] = JSON.stringify(filters);
  }

  if (continuationToken) {
    headers['x-continuation-token'] = continuationToken;
  }

  if (order && !_.isEmpty(order)) {
    let stringOrder = '';
    order.forEach((element) => {
      stringOrder += stringOrder === '' ? `${element.by} ${element.type}` : `, ${element.by} ${element.type}`;
    });
    headers['x-order'] = stringOrder;
  }

  const { data } = await axiosInstance().get('/career', { headers });
  return data;
};

export const getDepartments = async () => {
  const { data } = await axiosInstance().get('/career/departments');
  return data;
};
