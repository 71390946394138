import moment from 'moment/moment';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Button from '../shared/Button';

const BlogCard = ({ IsFeatured, Title, ShortDescription, ImageUrl, CreatedAt, Category, Id }) => {
  const cutNumber = 110;
  const truncateShortDescription = useMemo(() => {
    if (ShortDescription.length > cutNumber) {
      return ShortDescription.slice(0, cutNumber - 1) + '...';
    }
    return ShortDescription;
  }, [ShortDescription]);

  return (
    <div className="shadow rounded-lg relative grid grid-rows-[max-content]">
      <div style={{ backgroundImage: `url(${ImageUrl})` }} className="h-[250px] bg-cover bg-center rounded-tl-lg rounded-tr-lg relative" />
      {IsFeatured && <div className="absolute top-5 left-5 bg-blue-700 text-white-50 px-3 py-1 rounded-lg shadow uppercase">Featured</div>}
      <div className="p-5 grid content-between">
        <div>
          <Link to={Id} className="grid">
            <h5 className="text-xl font-bold text-blue-700 hover:underline">{Title}</h5>
          </Link>
          <p className="mb-2 text-light-grey-400 font-light">{moment(CreatedAt).format('MMMM DD, YYYY')}</p>
          <p className="text-light-grey-700">{truncateShortDescription}</p>

          <div className="mb-5 mt-2">
            <span className="border px-2 rounded-xl border-blue-700 text-light-grey-700 text-sm">{Category}</span>
          </div>
        </div>
        <Link to={Id} className="grid">
          <Button className="bg-blue-800 text-white-50 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900">Learn more</Button>
        </Link>
      </div>
    </div>
  );
};

export default BlogCard;
