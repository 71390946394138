import { Dropzone, FileItem } from '@dropzone-ui/react';
import React, { useState } from 'react';
import { sendEmailJobApplication } from '../../../services/emailApi';
import Alert from '../../shared/Alert';
import Button from '../../shared/Button';
import Loading from '../../shared/Loading';

// Libraries
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const ApplyForm = ({ position }) => {
  const [Name, setName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isHandled, setIsHandled] = useState(false);
  const [files, setFiles] = useState([]);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsError(false);
    setIsHandled(true);

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }
    const token = await executeRecaptcha('ContactForm');

    const formData = new FormData();
    formData.append('Name', Name);
    formData.append('Position', position.Title);
    formData.append('Email', Email);
    formData.append('Phone', Phone);
    formData.append('Message', Message);
    formData.append('Resume', files[0].file);
    formData.append('tokenCaptcha', token);

    try {
      const resp = await sendEmailJobApplication(formData);
      if (!resp || resp.message !== 'Email sent!') {
        setIsError(true);
      } else {
        setEmail('');
        setName('');
        setPhone('');
        setMessage('');
        setFiles([]);
      }
    } catch (error) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  return (
    <form onSubmit={handleForm}>
      <div className="form-group mb-3 grid md:grid-cols-3 gap-3">
        <input
          type="text"
          placeholder="Full Name"
          name="155211"
          id="field155211"
          className="form-control w-full block px-4 py-2 h-10 text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          value={Name}
          onChange={({ target: { value } }) => setName(value)}
          required
        />
        <input
          type="text"
          placeholder="Email"
          name="155212"
          id="field155212"
          className="form-control w-full block px-4 py-2 h-10 text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          value={Email}
          onChange={({ target: { value } }) => setEmail(value)}
          required
        />

        <input
          type="text"
          placeholder="Phone"
          name="155213"
          id="field155213"
          className="form-control w-full block px-4 py-2 h-10 text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          value={Phone}
          onChange={({ target: { value } }) => setPhone(value)}
          required
        />
      </div>

      <div className="form-group mb-3">
        <textarea
          placeholder="Cover Letter"
          name="155214"
          id="field155214"
          className="form-control w-full block px-4 py-2 text-light-grey-700 bg-white-50 bg-clip-padding border border-solid border-dark-grey-300 rounded transition ease-in-out m-0 focus:text-light-grey-700 focus:bg-white-50 focus:border-blue-600 focus:outline-none"
          value={Message}
          onChange={({ target: { value } }) => setMessage(value)}
          required
        ></textarea>
      </div>
      <div className="form-group mb-3 relative">
        <Dropzone
          style={{ minHeight: 280, maxHeight: 280 }}
          onChange={updateFiles}
          value={files}
          accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          behaviour="replace"
          disableScroll
          fakeUploading
          footer={false}
          label="Drop your CV here"
          maxFiles={1}
          maxFileSiz={20000000}
        >
          {files.length > 0 && files.map((file) => <FileItem {...file} onDelete={removeFile} key={file.id} info />)}
        </Dropzone>
      </div>
      <div className="text-center">
        <Button
          className="bg-white-100 text-mid-blue-900 hover:bg-white-50 focus:bg-white-50 active:bg-white-50 border border-blue-900"
          type="submit"
          disabled={isHandled && isLoading}
        >
          Submit your application
        </Button>
      </div>

      <div className="mt-5">
        {isLoading ? <Loading /> : isHandled && <Alert open={true} severity={isError ? 'error' : 'success'} message={isError ? 'Error, try later!' : 'Email sent!'} />}
      </div>
    </form>
  );
};

export default ApplyForm;
