import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../components/shared/Button';
import Loading from '../components/shared/Loading';
import { getPostById } from '../services/blogApi';
import CalendarIcon from '../assets/imgs/calendar.png';
import logo from '../assets/imgs/logo.svg';

const DetailsBlog = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { isLoading, data } = useQuery(['posts', { Id: id }], getPostById, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div style={{ minHeight: 'calc(100vh - 225px)' }}>
      <div className="bg-custom md:px-52 py-10 px-10">
        <h1 className="text-white-50 fk-about-title md:flex md: justify-center text-center mb-5 font-bold text-5xl flex items-center">
          Blog Factor <img className="h-24 w-21 inline-block" src={logo} alt="FactorK" />
        </h1>
        {data && <div className="text-center text-white-50 text-xl"></div>}

        <div className="text-center mt-5">
          <Button className="bg-white-50 text-mid-blue-900 hover:bg-white-100 focus:bg-white-100 active:bg-white-100" onClick={handleGoBack}>
            Back
          </Button>
        </div>
      </div>

      <div className="xl:px-52 md:px-20 px-10 my-10">
        <div className="grid grid-cols-12 gap-4">
          <div className="content col-span-12">
            {isLoading ? (
              <Loading />
            ) : (
              data && (
                <>
                  <h3 className="text-4xl font-bold mb-2 text-blue-700">{data.Title}</h3>
                  <div className="md:flex items-center justify-between mb-10">
                    <p className="flex items-center">
                      <img src={CalendarIcon} alt="calendar" className="mr-2" />
                      {moment(data.CreatedAt).format('MMMM DD, YYYY')}
                    </p>
                    <p className="border px-2 rounded-xl border-blue-700">{data.Category}</p>
                  </div>
                  <div className="text-xl" dangerouslySetInnerHTML={{ __html: data.Description }} />
                </>
              )
            )}
          </div>
          {/* <div className="sidebar bg-dark-blue-200 col-span-3">
            <section>
            </section>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DetailsBlog;
