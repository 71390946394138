const TestimonialCard = ({ photo, name, position, comments }) => {
  // const [readMore, setReadMore] = useState(false);

  // const cutNumber = 400;

  // const truncateComments = useMemo(() => {
  //   if (comments.length > cutNumber) {
  //     return {
  //       text: comments.slice(0, cutNumber - 1),
  //       readMore: true,
  //       otherText: comments.slice(cutNumber - 1, comments.length - 1),
  //     };
  //   }
  //   return {
  //     text: comments,
  //     readMore: false,
  //     otherText: '',
  //   };
  // }, [comments]);

  return (
    <div className="p-10 text-white-50 gap-4">
      <div>
        <img src={photo} alt="picture" className="rounded-full max-w-[125px]" />
        <h5 className="font-bold text-xl">{name}</h5>
        <h6 className="my-3 font-light">{position}</h6>
      </div>
      {/* <p className="italic text-lg font-light">
        "{truncateComments.text}
        {!truncateComments.readMore && '"'}
        {truncateComments.readMore && !readMore && (
          <>
            <span>..." </span>
            <span className="font-normal hover:text-white-100 cursor-pointer underline text-blue-300" onClick={() => setReadMore(true)}>
              Read more
            </span>
          </>
        )}
        <span className={!readMore ? 'hidden' : ''}>{truncateComments.otherText}" </span>
        {truncateComments.readMore && readMore && (
          <span className="font-normal hover:text-white-100 cursor-pointer underline text-blue-300" onClick={() => setReadMore(false)}>
            Show less
          </span>
        )}
      </p> */}
      <p className="italic text-lg font-light">"{comments}"</p>
    </div>
  );
};

export default TestimonialCard;
