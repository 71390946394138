import React, { useState } from 'react';

export const LayoutContext = React.createContext({});

export const LayoutProvider = ({ children }) => {
  const [snackbarInfo, setSnackbarInfo] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const setInfoSnackbar = (payload) => {
    setSnackbarInfo((prev) => ({ ...prev, ...payload }));
  };

  return <LayoutContext.Provider value={{ snackbarInfo, setInfoSnackbar }}>{children}</LayoutContext.Provider>;
};
