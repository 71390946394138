import React from 'react';
import FullLogo from '../assets/imgs/full-logo-blue.png';
import { Link } from 'react-router-dom';
import SocialMedia from './shared/SocialMedia';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="h-38 bg-dark-blue-100 text-mid-blue-900 fk-footer shadow">
      <div className="w-full h-full text-center p-5">
        <Link to="/">
          <img src={FullLogo} alt="logo footer" className="inline-block mb-2" />
        </Link>
        <p className="mb-3">© 2022-{year} Factor K 506, S.A. All Rights Reserved.</p>
        <SocialMedia />
      </div>
    </div>
  );
};

export default Footer;
