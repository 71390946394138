// Hooks
import { useQuery } from 'react-query';

// Components
import { Carousel } from 'react-responsive-carousel';
import Title from '../shared/Title';
import Loading from '../shared/Loading';
import Alert from '../shared/Alert';
import TestimonialCard from './TestimonialCard';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Services
import { getTestimonials } from '../../services/testimonialsApi';

const Testimonials = () => {
  const { isLoading, isError, data } = useQuery(['sherpas', { order: [{ by: 'CreatedAt', type: 'asc' }] }], getTestimonials, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const testimonials = (data?.data || []).filter((t) => t.User);

  return (
    <div className="w-full bg-custom py-10 text-center">
      <div className="3xl:max-w-[1500px] 3xl:inline-block">
        <Title text1="Sherpas" text2="Testimonials" isLight={true} colorForText={2} />
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert open={isError} severity="warning" message="Error loading data, try again later" />
        ) : testimonials.length > 0 ? (
          <Carousel
            centerMode={false}
            showStatus={false}
            showThumbs={false}
            infiniteLoop
            className="inline-block w-full"
            renderArrowPrev={(clickHandler) => (
              <button onClick={clickHandler} aria-label="previous slide / item" className="control-arrow control-prev rounded-none h-12 my-auto"></button>
            )}
            renderArrowNext={(clickHandler) => (
              <button onClick={clickHandler} aria-label="next slide / item" className="control-arrow control-next rounded-none h-12 my-auto"></button>
            )}
          >
            {testimonials.map((testimonial) => (
              <TestimonialCard
                key={testimonial.Id}
                comments={testimonial.Description}
                name={testimonial.User.Name}
                photo={testimonial.User.PhotoUrl}
                position={testimonial.User.Position}
              />
            ))}
          </Carousel>
        ) : (
          <Alert open={true} message="No testimonials available at this time" severity="info" />
        )}
      </div>
    </div>
  );
};

export default Testimonials;
