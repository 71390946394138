import React from 'react';
import Title from '../../shared/Title';

import BgTalkUs from '../../../assets/imgs/bg-talk-us.png';
import { useQuery } from 'react-query';
import { getClients } from '../../../services/clientsApi';
import Loading from '../../shared/Loading';
import Alert from '../../shared/Alert';

const Clients = () => {
  const { isLoading, isError, data } = useQuery(['clients', { order: [{ by: 'Order', type: 'asc' }] }], getClients, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <div style={{ backgroundImage: `url(${BgTalkUs})` }} className="bg-no-repeat bg-cover">
      <div className="grid md:grid-cols-2 gap-4 xl:px-52 md:px-20 px-10 py-10 items-center 3xl:max-w-[1500px] 3xl:inline-grid" >
        <div>
          <Title text1="Our" text2="Clients" colorForText={2} align="vertical" />
          <p className="text-light-grey-600 text-xl text-center">
            We are a customer-centric company and our goal is to align our capabilities with your digital strategy. We develop high-level digital products, support strategic
            projects, and provide the best talent.
          </p>
        </div>
        {isLoading ? (
          <Loading />
        ) : isError ? (
          <Alert open={isError} severity="warning" message="Error loading data, try again later" />
        ) : data && data.data.length > 0 ? (
          <div className="grid md:grid-cols-3 grid-cols-2 gap-4">
            {data.data.map((client) => (
              <div className="shadow border bg-white-50 border-white-200 rounded-md p-6 transition ease-in-out duration-150 hover:shadow-lg hover:border-blue-300" key={client.Id}>
                <img src={client.LogoUrl} alt={client.Name} />
              </div>
            ))}
          </div>
        ) : (
          <Alert open={true} message="No metrics available at this time" severity="info" />
        )}
      </div>
    </div>
  );
};

export default Clients;
