import React from 'react';
import Title from '../shared/Title';
import CareerPath from '../../assets/imgs/careers/CareerPath.png';
import Heart from '../../assets/imgs/careers/Heart.png';
import Training from '../../assets/imgs/careers/Training.png';
import OneToOne from '../../assets/imgs/careers/OnetoOne.png';
import CompanyMeeting from '../../assets/imgs/careers/meeting.png';
import Hiking from '../../assets/imgs/careers/hiking.png';
import PaidUSD from '../../assets/imgs/careers/hand.png';
import Birthday from '../../assets/imgs/careers/birthday-cake.png';

const Benefits = () => {
  return (
    <div className="xl:px-52 md:px-20 px-10 py-20 3xl:max-w-[1500px] 3xl:inline-block">
      <Title text1="Our" text2="Benefits" colorForText={2} />

      <div className="grid md:grid-cols-2 gap-4 text-xl">
        <div className="flex items-start">
          <img src={OneToOne} alt="icon" className="h-15 w-14 mr-5" />
          <div className="text-left">
            <h6 className="font-bold text-blue-900">Personal Follow Up</h6>
            <p className="text-light-grey-600">Meetings according to your career path </p>
          </div>
        </div>
        <div className="flex items-start justify-end">
          <div className="text-right">
            <h6 className="font-bold text-blue-900">Tech and Soft Skills Training</h6>
            <p className="text-light-grey-600">Web Platform access and company training</p>
          </div>
          <img src={Training} alt="icon" className="h-15 w-14 ml-5" />
        </div>
        <div className="flex items-start">
          <img src={CareerPath} alt="icon" className="h-15 w-14 mr-5" />
          <div className="text-left">
            <h6 className="font-bold text-blue-900">Career Path</h6>
            <p className="text-light-grey-600">We will design your plan together</p>
          </div>
        </div>
        <div className="flex items-start justify-end">
          <div className="text-right">
            <h6 className="font-bold text-blue-900">Company 1:1</h6>
            <p className="text-light-grey-600">Growth / 360 Meetings</p>
          </div>
          <img src={CompanyMeeting} alt="icon" className="h-15 w-14 ml-5" />
        </div>

        <div className="flex items-start">
          <img src={Hiking} alt="icon" className="h-15 w-14 mr-5" />
          <div className="text-left">
            <h6 className="font-bold text-blue-900">Sherpas Culture</h6>
            <p className="text-light-grey-600">We never climb to the top alone; we work together to get to the top</p>
          </div>
        </div>
        <div className="flex items-start justify-end">
          <div className="text-right">
            <h6 className="font-bold text-blue-900">Salary Paid</h6>
            <p className="text-light-grey-600">USD/CRC</p>
          </div>
          <img src={PaidUSD} alt="icon" className="h-15 w-14 ml-5" />
        </div>

        <div className="flex items-start">
          <img src={Birthday} alt="icon" className="h-15 w-14 mr-5" />
          <div className="text-left">
            <h6 className="font-bold text-blue-900">Monthly Birthday Celebrations</h6>
            <p className="text-light-grey-600">Every month we celebrate birthdays and anniversaries</p>
          </div>
        </div>
        <div className="flex items-start justify-end">
          <div className="text-right">
            <h6 className="font-bold text-blue-900">Medical Insurance</h6>
            <p className="text-light-grey-600">CCSS and INS / Private medical insurance</p>
          </div>
          <img src={Heart} alt="icon" className="h-15 w-14 ml-5" />
        </div>
      </div>
    </div>
  );
};

export default Benefits;
