import React from 'react';
import FacebookLogo from '../../assets/imgs/socialMedia/facebook.svg';
import InstagramLogo from '../../assets/imgs/socialMedia/instagram.svg';
import LinkedInLogo from '../../assets/imgs/socialMedia/linkedin.svg';

const SocialMedia = () => {
  return (
    <div className="flex items-center justify-center">
      <a href="https://www.facebook.com/factorksoftware" target="_blank" rel="noreferrer">
        <img src={FacebookLogo} alt="social media logo" className="w-7 h-7 mr-2 hover:scale-105 rounded-full transition ease-out" />
      </a>
      <a href="https://www.instagram.com/factorksoftware/" target="_blank" rel="noreferrer">
        <img src={InstagramLogo} alt="social media logo" className="w-7 h-7 mr-2 hover:scale-105 rounded-full transition ease-out" />
      </a>
      <a href="https://www.linkedin.com/company/factork-software-company/" target="_blank" rel="noreferrer">
        <img src={LinkedInLogo} alt="social media logo" className="w-7 h-7 hover:scale-105 rounded-full transition ease-out" />
      </a>
    </div>
  );
};

export default SocialMedia;
