import React from 'react';
import Title from '../../shared/Title';

import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getService } from '../../../services/serviceApi';
import Loading from '../../shared/Loading';
import Alert from '../../shared/Alert';

const Services = () => {
  const { isLoading, isError, data } = useQuery(['services', { order: [{ by: 'Order', type: 'asc' }] }], getService, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <div className="3xl:max-w-[1500px] inline-block xl:px-52 md:px-20 px-10 my-20">
      <Title text1="What" text2="We Offer" colorForText={2} />
      <div className="text-center">
        <p className="mb-5 text-light-grey-500 text-xl">
          We offer software development services in both project team and staff augmentation models. Our developers are focused on creating high-level digital products and
          supporting mission-critical systems.
        </p>
        <p className="text-light-grey-500 mb-10 text-xl">Among the services we offer:</p>
      </div>

      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Alert open={isError} severity="warning" message="Error loading data, try again later" />
      ) : data && data.data.length > 0 ? (
        <div className="grid md:grid-cols-3 sm:grid-cols-2 gap-y-10 xl:gap-x-44 lg:gap-x-11 gap-x-4">
          {data.data.map((service) => (
            <Link to={`/services/#${service.Id}`} key={service.Id}>
              <div className="border border-blue-700 p-5 shadow-md font-bold text-center rounded-lg hover:shadow-xl transition">
                <h6 className="mb-5 text-xl">{service.Title}</h6>
                <img src={service.LogoUrl} alt="icon" className="w-20 inline-block" />
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <Alert open={true} message="No services available at this time" severity="info" />
      )}
    </div>
  );
};

export default Services;
