import React from 'react';
import { Link } from 'react-router-dom';
import NotFoundSvg from '../components/NotFoundSvg';

const NotFound = () => {
  return (
    <div className="text-center mt-10">
      <p className="text-7xl md:text-8xl lg:text-9xl font-bold tracking-wider text-light-grey-600">404</p>
      <p className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider text-light-grey-600 mt-2">Page Not Found</p>
      <p className="text-lg md:text-xl lg:text-3xl text-dark-grey-800 my-12">Sorry, the page you are looking for could not be found.</p>
      <div>
        <Link to="/" className="inline-flex items-center justify-center space-x-2 w-72 bg-blue-600 hover:bg-blue-700 text-white-100 px-4 py-2 rounded transition duration-150">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span>Return Home</span>
        </Link>
      </div>

      <NotFoundSvg />
    </div>
  );
};

export default NotFound;
