import axiosInstance from './axiosInstance';

export const sendEmailContactUs = async (dataEmail) => {
  const { data } = await axiosInstance().post('/mail/contact-us', dataEmail);
  return data;
};

export const sendEmailJobApplication = async (dataEmail) => {
  const { data } = await axiosInstance().post('/mail/resume', dataEmail, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};
