import React from 'react';
import LinkedInWhite from '../../assets/imgs/socialMedia/linkedIn-white.svg';
import Button from '../shared/Button';

const CareersJoin = () => {
  return (
    <div className="xl:px-52 md:px-20 px-10 py-20 3xl:max-w-[1500px] 3xl:inline-block">
      <div className="grid md:grid-cols-2 gap-4 items-center">
        <div className='text-left'>
          <p className="font-bold text-3xl mb-5">Why work with Us?</p>
          <ul className="text-xl list-disc mb-10 pl-5">
            <li>You are part of a great company.</li>
            <li>We work together to design your career plan.</li>
            <li>We want you to support our strategy.</li>
            <li>We are family.</li>
            <li>We work together to build our dream.</li>
          </ul>
        </div>
        <div className="text-right">
          <h4 className="font-bold text-3xl mb-5">Join our Dream</h4>
          <p className="text-xl mb-5">
            All our positions at FactorK are remotely based. We work with amazing companies from the US to Latin America and our Sherpas are based in Costa Rica and El Salvador.
            Although we're a remote company, our full-time employees still receive a fantastic set of perks and benefits. Find out more below.
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4">
        <div className="text-center">
          <p className="text-xl mb-3">View our complete list of open positions in LinkedIn here:</p>
          <a href="https://www.linkedin.com/company/jobs-factor-k-software/posts" target="_blank" rel="noreferrer" className="inline-block">
            <Button className="bg-blue-800 text-white-50 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900 flex items-center">
              <img alt="linkedIn" src={LinkedInWhite} className="w-4 mr-2" />
              LinkedIn Jobs
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default CareersJoin;
