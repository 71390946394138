// Hooks
import { useQuery } from 'react-query';

// Components
import Alert from '../shared/Alert';
import Title from '../shared/Title';
import Loading from '../shared/Loading';
import MetricItem from './MetricItem';

// Services
import { getMetrics } from '../../services/metricsApi';

const Metrics = () => {
  const { isLoading, isError, data } = useQuery(['metrics', { order: [{ by: 'Order', type: 'asc' }] }], getMetrics, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <div className="pb-32 pt-24 3xl:max-w-[1500px] 3xl:inline-block">
      <Title text1="Our" text2="Metrics" colorForText={2} />

      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Alert open={isError} severity="warning" message="Error loading data, try again later" />
      ) : data && data.data.length > 0 ? (
        <div className="grid lg:grid-cols-5 md:grid-cols-3 gap-4">
          {data.data.map((metric) => (
            <MetricItem {...metric} key={metric.Id} />
          ))}
        </div>
      ) : (
        <Alert open={true} message="No metrics available at this time" severity="info" />
      )}
    </div>
  );
};

export default Metrics;
