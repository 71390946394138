import { useState } from 'react';
import Button from '../shared/Button';
import ApplyAnyPositionModal from './ApplyAnyPositionModal';

const ApplyAnyPositionContainer = () => {
  const [modalInfo, setModalInfo] = useState(false);

  const handleOpenInfo = () => {
    setModalInfo(true);
  };

  const handleCloseModal = () => {
    setModalInfo(false);
  };

  return (
    <div>
      <div className="mt-12 text-center">
        <p className="text-xl ">Didn't find any open position that fit your background?</p>
        <Button className="bg-blue-800 text-white-50 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900 inline-block mt-3" onClick={handleOpenInfo}>
          Send your resume
        </Button>
        <ApplyAnyPositionModal handleCloseModal={handleCloseModal} open={modalInfo} Title="Any Position" />
      </div>
    </div>
  );
};

export default ApplyAnyPositionContainer;
