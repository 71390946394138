import React, { useState } from 'react';
import { useQuery } from 'react-query';
import BlogCategory from '../components/blog/BlogCategory';
import PostsContainer from '../components/blog/PostsContainer';
import Loading from '../components/shared/Loading';
import { getCategories } from '../services/blogApi';
import logo from '../assets/imgs/logo.svg';

const Blog = () => {
  const [categorySelected, setCategorySelected] = useState('All');
  const { isLoading, data } = useQuery(['categories'], getCategories, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <>
      <div className="bg-custom md:px-52 py-10 px-10">
        <h1 className="text-white-50 fk-about-title md:flex md: justify-center text-center mb-5 font-bold text-5xl flex items-center">
          Blog Factor <img className="h-24 w-21 inline-block" src={logo} alt="FactorK" />
        </h1>
        <div className="text-center text-white-50 text-xl">If you want to keep up to date with our news, trends and life in the company, follow our blog.</div>
      </div>

      <div className="xl:px-52 md:px-20 px-10 mb-28 mt-10">
        <div className="md:flex items-center text-center mb-10">
          <BlogCategory text="All" selected={categorySelected} setSelected={setCategorySelected} />
          {isLoading ? (
            <Loading />
          ) : (
            data && data.data.map((category) => <BlogCategory key={category} text={category} selected={categorySelected} setSelected={setCategorySelected} />)
          )}
        </div>
        <PostsContainer categorySelected={categorySelected} />
      </div>
    </>
  );
};

export default Blog;
