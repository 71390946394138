import React from 'react';
import ContactForm from '../shared/ContactForm';
import Title from '../shared/Title';

const ContactUsForm = () => {
  return (
    <div className="xl:px-52 md:px-20 px-10 bg-custom py-10 text-center bg-center">
      <Title text1="Contact" text2="Us" isLight={true} colorForText={2} />
      <p className="mb-6 text-light-grey-100 text-3xl">Have any questions? We’d love to hear from you.</p>
      <ContactForm />
    </div>
  );
};

export default ContactUsForm;
