import React from 'react';
import BGSherpas from '../../assets/imgs/bg-sherpas.jpg';
import BGSherpasMD from '../../assets/imgs/bg-sherpas-md.png';
import BGSherpasSM from '../../assets/imgs/bg-sherpas-sm.png';
import Title from '../shared/Title';

const Culture = () => {
  return (
    <>
      <div className="3xl:max-w-[1500px] 3xl:inline-block xl:px-52 md:px-20 px-10 py-10 text-xl text-center">
        <Title text1="Our" text2="Community" colorForText={2} />
        <p>
          At Factor K, we work together to achieve individual and company goals. We are committed to achieving success for you, and for us. We adopt the Sherpa philosophy, which is
          working together and focusing efforts to reach the top.
        </p>
      </div>
      <div className='3xl:max-w-[1500px] 3xl:inline-block'>
        <img src={BGSherpas} alt="sherpas" className="hidden md:block" />
        <img src={BGSherpasMD} alt="sherpas" className="hidden sm:block md:hidden" />
        <img src={BGSherpasSM} alt="sherpas" className="sm:hidden" />
      </div>
    </>
  );
};

export default Culture;
