import React from 'react';
import Culture from '../components/aboutUs/Culture';
// import Kampus from '../components/aboutUs/Kampus';
import KernelValues from '../components/aboutUs/KernelValues';
import Metrics from '../components/aboutUs/Metrics';
import logo from '../assets/imgs/logo.svg';

const AboutUsPage = () => {
  return (
    <>
      <div className="bg-custom md:px-52 py-10 px-10">
        <div className="3xl:max-w-[1500px] 3xl:inline-block">
          <h1 className="text-white-50 fk-about-title md:flex md: justify-center text-center mb-5 font-bold text-5xl flex items-center">
            About Factor <img className="h-24 w-21 inline-block" src={logo} alt="FactorK" />
          </h1>
          <div className="text-center text-white-50 text-xl">
            <p className="mb-5">
              We strive to be your nearshore software technology partner or choice. We will support your digital strategy and solve your software development needs by providing you
              with the best nearshore talent through staff augmentation, dedicated teams, and consulting. Our company is headquartered in San José, Costa Rica (GMT-6, Central Time
              Zone). Our staff is culturally aligned with U.S. business practices. We are committed to growing with you and offering the best customer experience while attracting
              and retaining the best technical talent.
            </p>
          </div>
        </div>
      </div>

      <KernelValues />
      <Culture />
      <Metrics />
    </>
  );
};

export default AboutUsPage;
