import React from 'react';
import Tech from '../../assets/imgs/kernels/data-management.png';
import Efficiency from '../../assets/imgs/kernels/efficiency.png';
import Target from '../../assets/imgs/kernels/target.png';
import Rating from '../../assets/imgs/kernels/rating.png';

const KernelValues = () => {
  return (
    <>
      <div className="3xl:max-w-[1500px] 3xl:inline-block xl:px-52 md:px-20 px-10 py-10 text-xl text-center">
        <h4 className="font-bold text-3xl mb-5">Our Core Values</h4>
        <p>
          We use Technology, Efficiency, Customer-Centric Focus, and Talent Experience Culture to impact our clients and, all our capabilities are aligned to be your leading
          partner.
        </p>

        <p>
          We have an amazing recruitment process that finds the right talent. We care for our people and have very low turnover. We are able to provide competitive rates and apply
          our values to create true partnerships with our clients.
        </p>
        <div className="grid md:grid-cols-2 gap-9 md:my-16 my-5">
          <div className="md:flex items-start md:text-left text-center">
            <img src={Tech} alt="icon" className="mr-5 inline-block" />
            <div>
              <h6 className="font-bold text-xl text-blue-900">Technology</h6>
              <p>
                We use technology to increase the customer and talent experience. Our goal is to support every process to find a way to focus on our people, growth, and our
                relationship with our clients.
              </p>
            </div>
          </div>
          <div className="md:flex items-start md:text-left text-center">
            <img src={Efficiency} alt="icon" className="mr-5 inline-block" />
            <div>
              <h6 className="font-bold text-xl text-blue-900">Efficiency</h6>
              <p>
                We have a decision making model oriented towards investing in the areas to best impact our people and our clients. Through this, we are able to recruit the best
                talent, with high productivity and with attractive rates for our clients.
              </p>
            </div>
          </div>
          <div className="md:flex items-start md:text-left text-center">
            <img src={Target} alt="icon" className="mr-5 inline-block" />
            <div>
              <h6 className="font-bold text-xl text-blue-900">Customer-Centric</h6>
              <p>
                Usually, B2B businesses do not have a good customer experience. At Factor K, we are focused on creating long-term relationships based on accompaniment, commitment,
                respect, and excellence.
              </p>
            </div>
          </div>

          <div className="md:flex items-start md:text-left text-center">
            <img src={Rating} alt="icon" className="mr-5 inline-block" />
            <div>
              <h6 className="font-bold text-xl text-blue-900">Talent Experience Culture</h6>
              <p>
                We use a customer experience model to create our talent experience model. Our main objective is to define a personalized experience for everyone at Factor K: we get
                to know our Sherpas, their hobbies, and goals, and we are committed to working together to be the best place for all.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KernelValues;
