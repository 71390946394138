import React from 'react';
import ItemCard from './ItemCard';
import Title from '../../shared/Title';

import Angular from '../../../assets/imgs/techStack/angular.png';
import ReactLogo from '../../../assets/imgs/techStack/react.png';
import Vue from '../../../assets/imgs/techStack/vue.png';
import Node from '../../../assets/imgs/techStack/node-js.png';
import Python from '../../../assets/imgs/techStack/python.png';
import NetCore from '../../../assets/imgs/techStack/net-core.png';
import Ionic from '../../../assets/imgs/techStack/ionic.png';
import AWS from '../../../assets/imgs/techStack/aws.png';
import Azure from '../../../assets/imgs/techStack/azure.png';
import GoogleCloud from '../../../assets/imgs/techStack/google-cloud.png';
import Database from '../../../assets/imgs/techStack/database.png';
import Java from '../../../assets/imgs/techStack/java.png';
import PHP from '../../../assets/imgs/techStack/php.png';
import GoLang from '../../../assets/imgs/techStack/go.png';
import Kotlin from '../../../assets/imgs/techStack/kotlin.png';
import Swift from '../../../assets/imgs/techStack/swift.png';
import Oracle from '../../../assets/imgs/techStack/oracle.png';

const TechStack = () => {
  return (
    <div className="3xl:max-w-[1500px] 3xl:inline-block xl:px-52 md:px-20 px-10 my-20">
      <Title text1="Tech" text2="Stack" colorForText={2} />
      <div className="grid grid-cols-3 lg:grid-cols-6 md:grid-cols-5 sm:grid-cols-4 gap-4">
        <ItemCard img={Angular} name="Angular" />
        <ItemCard img={ReactLogo} name="React" />
        <ItemCard img={Vue} name="Vue" />
        <ItemCard img={Node} name="Node" />
        <ItemCard img={Python} name="Python" />
        <ItemCard img={NetCore} name=".Net" />
        <ItemCard img={Java} name="Java" />
        <ItemCard img={PHP} name="PHP" />
        <ItemCard img={GoLang} name="GoLang" />
        <ItemCard img={Kotlin} name="Kotlin" />
        <ItemCard img={Swift} name="Swift" />
        <ItemCard img={ReactLogo} name="React Native" />
        <ItemCard img={Ionic} name="Ionic" />
        <ItemCard img={AWS} name="AWS" />
        <ItemCard img={Azure} name="Azure" />
        <ItemCard img={GoogleCloud} name="Google Cloud" />
        <ItemCard img={Oracle} name="Oracle" />
        <ItemCard img={Database} name="SQL - NoSQL" />
      </div>
    </div>
  );
};

export default TechStack;
