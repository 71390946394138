export const dataSeverity = (severity) => {
  switch (severity) {
    case 'success':
      return {
        color: 'bg-green-600 text-white-50 fill-white-50',
        icon: `<path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z"/>`,
      };
    case 'error':
      return {
        color: 'bg-orange-700 text-white-50 fill-white-50',
        icon: `<path d="M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-1.402 16.945l-3.554-3.521-3.518 3.568-1.418-1.418 3.507-3.566-3.586-3.472 1.418-1.417 3.581 3.458 3.539-3.583 1.431 1.431-3.535 3.568 3.566 3.522-1.431 1.43z"/>`,
      };
    case 'warning':
      return {
        color: 'bg-yellow-400 text-dark-blue-900 fill-dark-blue-900',
        icon: `<path d="M12 5.177l8.631 15.823h-17.262l8.631-15.823zm0-4.177l-12 22h24l-12-22zm-1 9h2v6h-2v-6zm1 9.75c-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25 1.25.56 1.25 1.25-.561 1.25-1.25 1.25z"/>`,
      };
    default:
      return {
        color: 'bg-blue-700 text-white-50 fill-white-50',
        icon: `<path d="M12 24c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zm1-6h-2v-8h2v8zm-1-12.25c.69 0 1.25.56 1.25 1.25s-.56 1.25-1.25 1.25-1.25-.56-1.25-1.25.56-1.25 1.25-1.25z"/>`,
      };
  }
};

export const generateFilter = (oldFilters, name, value, evaluator) => {
  let element = oldFilters ? `${oldFilters} and ` : '$filter=';
  switch (evaluator) {
    case 'eq':
      element += `${name} eq '${value}'`;
      break;
    case 'lk':
      element += `substringof('${value}', ${name})`;
      break;
    default:
      element = oldFilters;
      break;
  }
  return element;
};

export const validateNumber = (number) => {
  const regex = /^\d+$/;
  return regex.test(number);
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ');
};
