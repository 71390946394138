import React from 'react';
import LocationIcon from '../../../assets/imgs/location.png';

const PositionCard = ({ onClick, position }) => {
  return (
    <div className=" bg-white-50 p-5 rounded-lg shadow hover:cursor-pointer group" onClick={() => onClick(position)}>
      <h4 className='relative inline-block text-xl font-bold after:content-[""] after:absolute after:w-full after:h-0.5 after:bottom-0 after:left-0 after:bg-blue-700 after:origin-bottom-right after:transition-transform after:duration-300 after:ease-out after:scale-x-0 group-hover:after:scale-100 group-hover:after:origin-bottom-left'>
        {position.Title}
      </h4>
      <p className="text-dark-grey-500">{position.Department}</p>
      <div className="flex items-center gap-1 mt-3">
        <img src={LocationIcon} alt="location marker" />
        <p>{position.Location}</p>
      </div>
    </div>
  );
};

export default PositionCard;
