import React from 'react';

const BlogCategory = ({ text, selected, setSelected }) => {
  const classItemSelected = selected === text ? 'after:scale-100' : 'after:scale-x-0 hover:after:scale-100 hover:after:origin-bottom-left';

  return (
    <div className="mr-4">
      <button
        className={`relative inline-block text-lg text-dark-blue-900 after:content-[""] after:absolute after:w-full after:h-0.5 after:bottom-0 after:left-0 after:bg-blue-700 after:origin-bottom-right after:transition-transform after:duration-300 after:ease-out ${classItemSelected}`}
        onClick={() => setSelected(text)}
      >
        {text}
      </button>
    </div>
  );
};

export default BlogCategory;
