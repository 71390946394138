import React from 'react';
import logo from '../../assets/imgs/logo.svg';

const CareersTittle = () => {
  return (
    <div className="w-full bg-custom">
      <div className="container mx-auto py-8">
        <h1 className="text-white-50 fk-about-title md:flex md: justify-center text-center mb-5 font-bold text-5xl flex items-center">
          Careers <img className="h-24 w-21 inline-block" src={logo} alt="FactorK" />
        </h1>
      </div>
    </div>
  );
};

export default CareersTittle;
