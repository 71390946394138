// Hooks
import { useQuery } from 'react-query';

// Components
import ItemCard from './ItemCard';
import Title from '../shared/Title';
import Alert from '../shared/Alert';
import Loading from '../shared/Loading';

// Services
import { getTeam } from '../../services/teamApi';

const AdvisoryContainer = () => {
  const { isLoading, isError, data } = useQuery(['council', { filters: { default: `$filter=TypePosition eq 'advisor'` }, order: [{ by: 'Name', type: 'asc' }] }], getTeam, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <>
      <Title text1="Advisory" text2="Team" colorForText={2} />
      {isLoading ? (
        <Loading />
      ) : isError ? (
        <Alert open={isError} severity="warning" message="Error loading data, try again later" />
      ) : data && data.data.length > 0 ? (
        <div className="grid md:grid-cols-4 grid-cols-2 gap-4">
          {data.data.map((user) => (
            <ItemCard key={user.Id} link={user.LinkedIn} name={user.Name} photo={user.PhotoUrl} position={user.Position} />
          ))}
        </div>
      ) : (
        <Alert open={true} message="No information available at this time" severity="info" />
      )}
    </>
  );
};

export default AdvisoryContainer;
