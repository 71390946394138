import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import Careers from './pages/Careers';
import NotFound from './pages/NotFound';
import Services from './pages/Services';
import Team from './pages/Team';
import Blog from './pages/Blog';
import AboutUs from './pages/AboutUsPage';
import DetailsBlog from './pages/DetailsBlog';
import { LayoutProvider } from './context/LayoutContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactGA from 'react-ga4';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

ReactGA.initialize('G-2NVW02PKHJ');
const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LayoutProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="careers" element={<Careers />} />
            <Route path="services" element={<Services />} />
            <Route path="team" element={<Team />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:id" element={<DetailsBlog />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </LayoutProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
