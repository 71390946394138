import React from 'react';
import { Link } from 'react-router-dom';

import ContactUsForm from '../components/home/ContactUsForm';
import Services from '../components/home/services/Services';
import Button from '../components/shared/Button';
import TechStack from '../components/home/techStack/TechStack';
import Clients from '../components/home/clients/Clients';
// import Locations from '../components/home/Locations';

import BgTalkUs from '../assets/imgs/bg-talk-us.png';
import FullLogo from '../assets/imgs/full-logo.png';
import BgHero from '../assets/imgs/bg-hero.jpg';
import PhoneIcon from '../assets/imgs/office-phone.png';
import Title from '../components/shared/Title';

const Home = () => {
  return (
    <>
      <div className="md:p-16 p-5 h-128 flex justify-center items-center bg-cover bg-center" style={{ backgroundImage: `url(${BgHero})` }}>
        <div className="text-center">
          <img src={FullLogo} alt="logo" className="inline-block mb-10" />
          <h3 className="text-center text-white-50 font-semibold text-4xl mb-5">We strive to be your nearshore software technology partner of choice</h3>
        </div>
      </div>

      <div className="bg-center bg-cover bg-no-repeat md:px-52 py-10 px-10" style={{ backgroundImage: `url(${BgTalkUs})` }}>
        <div className="3xl:max-w-[1500px] inline-block">
          <Title text1="About" text2="Us" colorForText={2} />
          <div className="text-center">
            <p className="text-light-grey-500 text-xl mb-5">
              We strive to be your main nearshore software technology partner. We will support your digital strategy and solve your software development needs by providing you with
              the best nearshore talent through staff augmentation, dedicated teams, and consulting.
            </p>

            <Link to="/about-us">
              <Button className="bg-blue-800 text-white-50 hover:bg-mid-blue-900 focus:bg-mid-blue-900 active:bg-mid-blue-900">Read More</Button>
            </Link>
          </div>
        </div>
      </div>

      <Services />

      <div className="bg-white-100 py-14 my-20 bg-center bg-cover bg-no-repeat" style={{ backgroundImage: `url(${BgTalkUs})` }}>
        <div className="3xl:max-w-[1500px] 3xl:inline-flex w-full">
          <div className="md:flex items-center justify-around text-center w-full">
            <h4 className="text-center text-3xl font-bold text-blue-700 block md:hidden mb-5">Talk to Us</h4>
            <a href="tel:+1(786)574-4150" target="_blank" rel="noreferrer">
              <img alt="phone" src={PhoneIcon} className="inline-block mb-2 shadow-lg rounded-full" />
              <h6 className="font-semibold text-xl">United States Office</h6>
              <p className="underline text-blue-700 mb-5 md:mb-0">+1 (786) 574-4150</p>
            </a>
            <h4 className="text-center text-3xl font-bold text-blue-700 hidden md:block">Talk to Us</h4>
            <a href="tel:+50640022595" target="_blank" rel="noreferrer">
              <img alt="phone" src={PhoneIcon} className="inline-block mb-2 shadow-lg rounded-full" />
              <h6 className="font-semibold text-xl">Costa Rica Office</h6>
              <p className="underline text-blue-700">+506 4002-2595</p>
            </a>
          </div>
        </div>
      </div>
      <TechStack />
      {/* <Locations /> */}
      <Clients />
      <ContactUsForm />
    </>
  );
};

export default Home;
