import React from 'react';
import LinkedIn from '../../assets/imgs/socialMedia/linkedin.svg';

const ItemCard = ({ name, position, photo, link, isCouncil = true, classes }) => {
  return (
    <div className={`rounded-lg shadow-md text-center p-5 bg-white-50 ${classes}`}>
      <img src={photo} alt="picture" className="rounded-full w-56 inline-block" />
      <h2 className="text-3xl text-blue-700 mt-3 font-bold">{name}</h2>
      <p className="text-dark-grey-700 font-light min-h-[48px] flex items-center justify-center">{position}</p>
      {isCouncil && (
        <>
          <hr className="w-11 border-blue-700 border-b-2 inline-block border-t-0 -mb-2" />
          <p className="text-dark-grey-700 font-light py-1">Board Member</p>
        </>
      )}
      <hr className="mb-4 w-11 border-blue-700 border-t-2 inline-block" />
      <div>
        <a href={link} target="_blank" rel="noreferrer">
          <img src={LinkedIn} className="w-7 inline-block" alt={name} />
        </a>
      </div>
    </div>
  );
};

export default ItemCard;
