import React from 'react';

const Button = ({ children, className, onClick, type = 'button', disabled = false }) => {
  const classDisabled = 'cursor-not-allowed opacity-80';
  return (
    <button
      className={`inline-block px-7 py-3 font-medium text-sm leading-snug uppercase rounded shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg ${className} ${
        disabled ? classDisabled : ''
      }`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
